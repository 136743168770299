<template>
    <div class="Profile-subject" v-loading.lock="loadingService">
        <div class="subject-card pb-5" v-if="!loadingService">
            <div @click="$router.push({ name: 'lessons' })" class="subject-card__back-btn">
                <img src="/images/profile/arrow-prev.svg" width="9" height="15" alt="">
            </div>
            <div class="subject-card__header">
                <div class="subject-card__title">
                    {{ lesson.subject }}
                </div>
                <div class="subject-card__thema">
                    <div v-if="lesson.topicContent">{{$t("profile.subject.thema-title")}} {{ lesson.topicContent.name }}</div>
                </div>
                <div class="subject-card__date">
                    <div>{{$t("profile.subject.date")}} {{ lesson.date }}</div>
                </div>
            </div>
            <div class="subject-card__text" v-if="lesson.topicContent">
                <span class="subject-card__sub-title">{{$t("profile.subject.description-title")}}</span>
                {{ lesson.topicContent.lesson_purpose }}
            </div>
            <div class="subject-card__text" v-if="lesson.topicContent">
                <span class="subject-card__sub-title">Полное описание урока:</span>
                <div class="subject-card__section-inside" v-html="lesson.topicContent.lesson_content"></div>
            </div>
            <div class="subject-card__text">
                <span class="subject-card__sub-title">{{$t("profile.subject.teacher-title")}}</span>
                {{ lesson.teacherName }}
            </div>
            <div class="subject-card__text mt-3" v-if="lesson.topicFiles.length">
                <div class="subject-card__sub-title">{{$t("profile.subject.lesson-materials")}}</div>
            </div>

            <div class="download-materials" v-if="lesson.topicFiles.length">
                <div class="download-materials__pair">
                    <a :href="apiRoot + '/storage/' + item.file" target="_blank" class="download-material" v-for="item in lesson.topicFiles">
                        <div class="download-material__title">{{ item.file.substring(6) }}</div>
                        <img src="/images/profile/download-file.svg" alt="">
                    </a>
                </div>
            </div>
            <div class="subject-card__download-block mb-5" v-if="lesson.topicFiles.length">
                <div class="subject-card__or">
                    {{$t("profile.subject.or")}}
                </div>
                <button class="subject-card__download-btn">
                    {{$t("profile.subject.download")}}
                </button>
            </div>
            <div class="subject-card__connect-block" v-if="lesson.started && lesson.ended">
                <div class="subject-card__lesson-type">
                    <span class="subject-card__type">{{$t("profile.subject.lesson-type")}}</span>
                    {{$t("profile.subject.online")}}
                </div>
                <a @click.prevent="watchLesson" href="#" class="subject-card__connect">{{$t("profile.schedule.watch-recorded-lesson")}}</a>
            </div>
            <div v-else>
                <div class="subject-card__connect-block" v-if="!lesson.ended && lesson.teacherCame">
                    <div class="subject-card__lesson-type">
                        <span class="subject-card__type">{{$t("profile.subject.lesson-type")}}</span>
                        {{$t("profile.subject.online")}}
                    </div>
                    <a @click.prevent="goToConference" href="#" class="subject-card__connect">{{$t("profile.connect-to-lesson")}}</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";

export default {
    name: "Lesson",
    data() {
        return {
            loadingService: false,
            loading: false,
            uploadUrl: window.API_ROOT + '/api/file/upload',
            lesson: {
                homework: {},
                homeworkFiles: [],
                topicFiles: [],
                topicContent: {},
            },
            homeworkResponse: {
                id: '',
                comment: '',
                files: []
            },
            fileList: [],
            apiRoot: window.API_ROOT
        }
    },
    methods: {
        onProgress(res, file, fileList) {
            this.homeworkResponse.files = fileList;
        },
        onRemove(file, fileList) {
            this.homeworkResponse.files = fileList;
            this.fileList = fileList;
        },
        sendHomeworkResponse() {
            this.loading = true
            let id = this.$route.params.id
            this.$http.post(`${window.API_ROOT}/api/student/send-hw/${id}`, this.homeworkResponse)
                .then(() => {
                    this.loading = false
                    Vue.toastr({
                        message: 'Данные сохранены',
                        description: '',
                        type: 'success'
                    })
                    window.scrollTo(0,0);
                })
                .catch(() => {
                    this.loading = false
                })
        },
        goToConference()
        {
            this.$router.push({ name: 'conference', params: { room: this.lesson.room_id } }).catch(err => {
                console.log(err)
            })
        },
        watchLesson()
        {
            window.open(`http://92.46.110.138/?file=${this.lesson.room_id}`, '_blank')
        }
    },
    mounted() {
        this.loadingService = true;
        setTimeout(() => {
            this.loadingService = false;
        }, 5000);
        let id = this.$route.params.id
        this.$http.get(`${window.API_ROOT}/api/student/lesson/${id}`)
            .then((res) => {
                this.lesson = res.body.data
                this.homeworkResponse.homework_id = this.lesson.homework.id
                this.homeworkResponse.comment = this.lesson.homeworkResponse.student_comment
                this.homeworkResponse.files = this.lesson.homeworkResponseFiles
                this.homeworkResponse.files.forEach(item => {
                    item.response = item.file
                    item.name = item.file.substring(6)
                    this.fileList.push(item)
                })
                this.loadingService = false
            })
            .catch(() => {
                this.loadingService = false
            })
    },
}
</script>

<style lang="less" scoped>
.footer-wave{
    margin-top: 2rem;
    height: 90px;
    width:100%;
    background-image: url("/images/footer-wave-ktp.svg");
    background-size: cover;
    background-repeat: no-repeat;
}
.or-ktp{
    width:max-content;
    margin: 15px auto 20px;
}
.btn-download {
    width: 146px;
    margin: 0 auto;
    user-select: none;
    background: #473F95;
    border-radius: 5px;
    cursor:pointer;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 32px;
    text-align: center;
}
.text-danger .file-download-img {
    background-image: url("/images/file-download-red.svg");
}
.file-download-img{
    background-image: url("/images/file-download.svg");
    margin: 12px auto 0;
    width: 19px;
    height: 31px;
    background-size: contain;
}
.file-download:hover{
    text-decoration: none;
    color: #7773a2;
}
.file-download{
    user-select: none;
    color: #473F95;
    cursor: pointer;
    margin: 0 auto;
    padding:5px;
    text-overflow: ellipsis;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    width: 120px;
    height: 75px;
    border: 1px dashed #473F95;
    border-radius: 4px;
}
.file-download span{
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.text-danger .file-download {
    border-color: #d23267;
}
.text-danger .file-download span {
    color: #d23267;
}
.ktp-file-download-wrapper{
    margin-top: 1rem;
    display:flex
}
.ktp-info-section-inside{
    margin-top: 15px;
    background: #EEEAFB;
    border-radius: 4px;
    padding: 25px;
}
.ktp-info-section{
    margin-top: 32px;
    b{font-weight: bold}
}
.ktp-info-section .bordered-block {
    border: 1px dashed #d23267;
    border-radius: 4px;
    display: block;
    overflow: hidden;
    max-width: 300px;
    margin: 0 auto;
    padding: 25px;
}
.subject-card {
    padding: 36px 45px 36px 30px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    max-width: 970px;
    margin: 0;
    position: relative;
    background: #ffffff url("/images/profile/subject-bg.svg") no-repeat bottom;
    background-size: contain;
}
.subject-card__back-btn {
    border: none;
    background: #EEEAFB;
    border-radius: 5px 0;
    width: 59px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
}
.subject-card__section-inside{
    margin-top: 15px;
    background: #EEEAFB;
    border-radius: 4px;
    padding: 25px;
}
.subject-card__header {
    text-align: center;
}
.subject-card__title {
    color: #473F95;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
}
.subject-card__thema {
    color: #473F95;
    font-size: 20px;
    line-height: 23px;
    padding: 8px 0;
}
.subject-card__date {
    color: #D23168;
    font-size: 18px;
    line-height: 21px;
    padding-bottom: 45px;
}
.subject-card__text {
    color: #473F95;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 11px;
}
.subject-card__sub-title {
    font-weight: bold;
}
.download-materials {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 11px;
}
.download-materials__pair {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.download-material {
    overflow: hidden;
    text-align: center;
    width: 118px;
    border: 1px dashed #473F95;
    border-radius: 4px;
    margin-right: 20px;
    padding: 6px;
}
.download-material__title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #473F95;
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 11px;
}
.download-materials.text-danger .download-material {
    border-color: #D23168;
}
.download-materials.text-danger .download-material__title{
    color: #D23168;
}
.subject-card__download-block {
    text-align: center;
    width: fit-content;
}
.subject-card__or {
    color: #473F95;
    font-size: 14px;
    line-height: 16px;
    padding-bottom: 10px;
}
.subject-card__download-btn {
    color: #FFFFFF;
    font-size: 14px;
    line-height: 16px;
    padding: 8px 22px;
    border: none;
    background: #473F95;
    border-radius: 4px;
}
.subject-card__lesson-type {
    color: #D23168;
    font-size: 18px;
    line-height: 21px;
    padding: 38px;
    border: 1px dashed #D23168;
    width: fit-content;
    margin: 0 auto 24px;
}
.subject-card__connect-block {
    text-align: center;
    margin-bottom: 122px;
}
.subject-card__type {
    font-weight: bold;
}
.subject-card__connect {
    color: #FFFFFF;
    font-family: 'Inter', 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    padding: 14px 36px;
    background: #D23168;
    border-radius: 5px;
    border: none;
}
.subject-card__comment-desc {
    color: #473F95;
    font-size: 16px;
    line-height: 19px;
    padding: 16px;
    background: #EEEAFB;
    border-radius: 4px;
    margin-bottom: 4px;
}
.download-materials-block--pink .subject-card__sub-title,
.download-materials-block--pink .download-materials,
.download-materials-block--pink .download-material__title,
.download-materials-block--pink .subject-card__or {
    color: #D23168;
}
.download-materials-block--pink .download-material {
    border: 1px dashed #D23168;
}
.download-materials-block--pink .subject-card__download-btn {
    background: #D23168;
}
.subject-card__status {
    position: absolute;
    background: #EEEAFB;
    border-radius: 0 10px;
    top: 0;
    right: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #473F95;
    width: 156px;
    height: 59px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.subject-card__status .subject-card__status-checked {
    display: none;
}
.subject-card__status--pink {
    color: #D23168;
    background: #FBEAEA;
}

.subject-select {
    width: 260px;
    height: 36px;
    margin-right: 10px;
}

.class-select {
    width: 154px;
    height: 36px;
}
.add-home-work__upload {
    color: #d23267;
    width: calc(100% + 40px);
}
/deep/ .add-home-work__upload .el-upload-dragger {
    border: 1px dashed #d23267;
}
/deep/ .add-home-work__upload .el-upload-list__item .el-icon-close-tip {
    opacity: 0;
}
/deep/ .add-home-work__upload .el-upload--picture-card:hover,
/deep/ .add-home-work__upload .el-upload:focus {
    border: none;
    color: #d23267;
}
/deep/ .add-home-work__upload .el-upload-list {
    width: 110%;
    max-width: 400px;
    margin-left: -20px;
}
/deep/ .add-home-work__upload .el-upload-list__item.is-success .el-upload-list__item-name:focus,
/deep/ .add-home-work__upload .el-upload-list__item.is-success .el-upload-list__item-name:hover {
    color: #d23267;
}
/deep/ .add-home-work__upload .el-upload-list .el-upload-list__item{
    border: 1px dashed #d23267;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 5px;
    width: 93px;
    position: relative;
    text-align: center;
    float: left;
    margin: 30px 20px 0;
}
/deep/ .add-home-work__upload .el-upload-list .el-upload-list__item-name{
    margin-right: 0;
    padding-left: 0;
}
/deep/ .add-home-work__upload .el-upload-list .el-upload-list__item-name:hover{
    text-decoration: none;
}
/deep/ .add-home-work__upload .el-upload-list .el-upload-list__item-name .el-icon-document{
    clear: both;
    display: block;
    margin: 0 auto;
}
/deep/ .add-home-work__upload .el-upload-list .el-upload-list__item .el-progress {
    width: 90%;
}
/deep/ .add-home-work__upload .el-upload-list .el-upload-list__item .el-icon-close{
    position: absolute;
    right: -8px;
    top: -8px;
    border: none;
    border-radius: 50%;
    background-color: #D23168;
    width: 22px;
    height: 22px;
    align-items: center;
    justify-content: center;
    opacity: 1;
    color: #fff;
    padding: 4px;
    font-weight: bold;
}
</style>
